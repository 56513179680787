<template>
  <div>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "Verify Token",
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    const token = this.$route.params.token;
    if (token) {
      this.verifyToken(token);
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    verifyToken(token) {
      this.loading = true;
      this.$store.dispatch("auth/loginAsUser", token).then(
        () => {
          this.$router.push("/home");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
