import store from "./store";

// Navigation guard to check if the user has permission to access a specific route
export function hasPermission(requiredPermission) {
  // Access userPermissions from the Vuex store after dispatching the action
  const userPermissions = store.getters["auth/userPermissions"];
  return isAuthenticated() && userPermissions.includes(requiredPermission);
}

function isAuthenticated() {
  const currentUser = store.state.auth.user;
  return currentUser !== null;
}

export function setupRouterGuard(router) {
  router.beforeEach(async (to, from, next) => {
    const defaultTitle = "Tarana Wireless";
    // Helper function to format titles
    const formatTitle = (str) =>
      str
        ? str
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
        : "";
    const pageNameMap = {
      cto: "CTO",
      specs: "Specifications",
      "noc-operator": "NOC Operator",
    };

    let titleParts = [to.name ?? "Home"];
    if (to.params.page_name) {
      const readableTitle =
        pageNameMap[to.params.page_name] || formatTitle(to.params.page_name);
      titleParts.push(readableTitle);
    }
    if (to.hash != "" && to.hash != "#") {
      titleParts.push(formatTitle(to.hash.replace("#", "")));
      localStorage.setItem("lastVisitedURL", to.fullPath);
    }
    titleParts.push(defaultTitle);
    document.title = titleParts.filter(Boolean).join(" - ");
    let excludedUrls = [
      "Error",
      "Forbidden",
      "Login",
      "Confirm Email",
      "Verify Token",
      "Forgot Password",
      "Reset Password",
      "Register",
      "Tarana Resources",
    ];
    if (!excludedUrls.includes(to.name) && to.fullPath != "/") {
      localStorage.setItem("lastVisitedURL", to.fullPath);
    }
    const isAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isHide = to.matched.some((record) => record.meta.hideForAuth);
    let isPermission = false;
    let requiredPermission = "";
    if (to.path.split("/")[1] == "onboarding") {
      document.body.classList.add("onboarding-bg");
    } else {
      document.body.classList.remove("onboarding-bg");
    }
    // If there's a `page_name` parameter in the route, use it to check permissions
    if (to.params.page_name) {
      const permissions = to.meta.permissions || [];
      isPermission = permissions.includes(to.params.page_name);
      requiredPermission = to.params.page_name;
    } else {
      isPermission = to.matched.some((record) => record.meta.permission);
      requiredPermission = to.meta.permission;
    }

    // Fetch user permissions before proceeding
    if (isAuthenticated()) {
      try {
        await store.dispatch("auth/permissions", store.state.auth.user);
        if (to.params.token && to.params.email) {
          isHide = isAuthenticated() ? false : true;
        }

        if (isAuth && !isAuthenticated()) {
          // Redirect to the login page if the route requires authentication
          next({ path: "/login" });
        } else if (isPermission && !hasPermission(requiredPermission)) {
          // Redirect to a forbidden page or display an error message if the user does not have permission
          next({ path: "/forbidden" });
        } else {
          // Allow access to the route
          next();
        }
      } catch (error) {
        // Handle permission fetching error here, if needed
        console.error("Permission fetching error:", error);
        next(); // Proceed without permissions if there's an error
      }
    } else {
      // Handle the case when the user is not authenticated
      // Redirect to the login page or handle it as per your app's requirements
      if (isAuth) {
        next({ path: "/login" });
      } else {
        next();
      }
    }
  });
}
