<template>
  <section class="h-100 pt-3">
    <div class="container h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-xl-10">
          <div class="rounded-3 text-black">
            <div class="row g-0 justify-content-center">
              <div class="col-lg-6">
                <div class="p-md-5 mx-md-4">
                  <div class="text-center">
                    <img
                      src="../assets/images/logo-black.png"
                      style="width: 185px"
                      alt="logo"
                    />
                    <p>Please login to your account</p>
                  </div>
                  <Form @submit="handleLogin" :validation-schema="schema">
                    <div class="form-outline my-4">
                      <div
                        v-if="message"
                        class="alert alert-danger alert-dissmissable"
                        role="alert"
                      >
                        {{ message }}
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 mb-4">
                      <div class="form-group">
                        <label for="email">Email address</label>
                        <Field
                          name="email"
                          type="text"
                          class="input-custom mb-0"
                          placeholder="Enter email address"
                        />
                        <ErrorMessage name="email" class="error-feedback" />
                      </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                      <div class="form-group">
                        <label for="password">Password</label>
                        <Field
                          name="password"
                          type="password"
                          class="input-custom mb-0"
                          placeholder="Enter password"
                        />
                        <ErrorMessage name="password" class="error-feedback" />
                        <p class="small-text">Make sure it's 8+ characters</p>
                      </div>
                    </div>

                    <div class="forgot-pass">
                      <router-link to="/forgot" class="text-muted"
                        >Forgot password?</router-link
                      >
                    </div>
                    <div class="text-center pt-1 pb-1">
                      <button class="button-indigo" type="submit">Log in</button
                      ><br />
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-center pb-4"
                    >
                      <p class="mb-0 me-2">Don't have an account?</p>
                      <router-link :to="{ path: '/register' }" tag="button"
                        >Register here</router-link
                      >
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().required("Username is required!"),
  password: yup.string().required("Password is required!"),
});

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    const validate = this.$route.query.v;
    if (validate) {
      this.message = "Your account is not verified";
      this.$router.push({ path: "/login" });
    }
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;
      this.$store.dispatch("auth/login", user).then(
        () => {
          const lastVisitedURL = localStorage.getItem("lastVisitedURL") ?? "";
          if (lastVisitedURL) {
            const [path, hash] = lastVisitedURL.split("#");
            this.$router.push({ path, hash: hash ? `#${hash}` : "" });
          } else {
            this.$router.push("/home");
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
